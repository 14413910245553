@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans',-apple-system, BlinkMacSystemFont,'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Source Sans Pro',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dark {
  color: var(--primaryText);
}

.app {
  display: grid;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "topBar topBar"
    "sideMenu main"
    "bottomNavigation bottomNavigation"
}

.app > .side-menu {
  grid-area: sideMenu;
}
.app > .top-bar {
  grid-area: topBar;
}

.sidenav {
  grid-area: sidenav;
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  opacity: 0.8;
  z-index: 2;
}

.sidenav .filter {
  padding: 20px;
}

.filter input {
  width: 75%;
  font-size: 75%;
  border: none;
  border-radius: 0.5em;
  padding: 4px 4px 4px 24px;
}

.navigation {
  padding: 10px 0 0 0;
  list-style-type: none;
}

.navigation li {
  padding: 14px 14px 14px 0px;
  font-size: 90%;
  margin-left: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navigation li.active {
  border-right: 2px solid;
}

.navigation li i {
  font-size: 150%;
  margin-right: 12px;
}

.navigation li:hover {
  cursor: pointer;
}

.navigation .divider {
  height: 1px;
  display: flex;
  width: 100%;
}

.mainnav,
.accountnav,
.pagetools {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.mainnav {
  margin-left: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.mainnav li,
.accountnav li,
.pagetools li {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.mainnav li {
  padding: 0;
}

.mainnav li a {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 0 16px;
  text-decoration: none;
}

.mainnav .mainitem,
.accountnav .accountitem,
.pagetools .pageitem {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.alert-bubble {
  height: 1rem ;
  margin-top: 3px;
  color: black !important;
}

.collapsed .alert-bubble {
  position:absolute;
  right:0px;
  top: -6px;
  font-size: 0.6rem; margin-top: 0px;
}

.accountnav .accountitem,
.pagetools .pageitem {
  width: 34px;
  height: 34px;
}

.mainnav .mainitem i {
  margin-right: 8px;
}

.accountnav {
  margin-right: 36px;
}

.pagetools .pageitem {
  color: var(--secondaryText);
}

.accountnav .btn-link {
  color: var(--menuTextColor) !important;
}

.accountnav .btn-link:hover {
  color: var(--mainNavActiveTextColor) !important;
}

body.dark .accountnav .btn-link {
  color: var(--secondaryText) !important;
}

body.dark .accountnav .btn-link:hover {
  color: var(--primaryText) !important;
}

.pagetools li:hover .pageitem {
  color: var(--primaryText);
}

.mainnav li a {
  display: flex;
}

.app > .main {
  grid-area: main;
  display: grid;
}

.app > .main {
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "sidenav center";
  overflow: hidden;
}

.app main {
  grid-area: center;
  padding: 0 0px;
  height: 100%;
}

.pageheader {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

input.title-edit,
.pageheader h6 {
  font-weight: 100;
  margin-bottom: 0;
  margin-left: 30px !important;
  color: var(--secondaryText);
}
input.title-edit {
  font-size: 1rem;
  margin-left: 30px !important;
  color: var(--dashboard-title-color)
}
.title-edit {
  background: none;
  border: none;
  border-bottom: 1px dashed;
  height: 20px;
}

.title-edit:focus {
  outline: none;
}

.pagecontent {
  display:flex;
  flex-direction: column;
  min-height: 60vh;
  height: auto;
  /*max-height: calc(100vh - 66px - 58px);*/
}
.fullscreen-enabled {
  background: var(--backgroundColor);
}
.fullscreen-enabled .page-content {
  max-height: 100vh;
  background: var(--backgroundColor);
}

button.main {
  border: none;
  font-size: 0.8rem;
}

button.main:hover {
  border: none;
}

input:-webkit-autofill::first-line {
  font-size: 16px;
}

body.theme-transition,
body.theme-transition *,
body.theme-transition *:before,
body.theme-transition *:after,
.app.theme-transition,
.app.theme-transition *,
.app.theme-transition *:before,
.app.theme-transition *:after {
  transition: all 750ms !important;
  transition-delay: 0ms !important;
}

.app > header {
  background-color: var(--headerBackground);
}

.sidenav {
  background-color: var(--sideBackground);
}

.navigation li {
  color: var(--navTextColor);
}

.navigation li.active {
  border-right-color: var(--navActiveBorder);
  background-color: var(--navActive);
  color: var(--navActiveTextColor);
  font-weight: bold;
}

.navigation li:hover {
  background-color: var(--navHover);
}

.navigation .divider {
  background-color: var(--navDivider);
}

.mainnav li,
.accountnav li,
.pagetools li,
.mainnav li a,
.accountnav li a,
.pagetools li a {
  color: var(--menuTextColor);
}

.pagetools li {
  color: var(--pageToolsTextColor);
}

.mainnav li:hover {
  color: var(--mainNavHoverTextColor);
  background-color: var(--mainNavHover);
}

.accountnav li:hover {
  color: var(--accountNavHoverTextColor);
}

.mainnav li.active {
  background-color: var(--mainNavActive);
  border-bottom-color: var(--mainNavActiveBorder);
}

.mainnav li.active a {
  color: var(--mainNavActiveTextColor);
}

.app > .main {
  background-color: var(--mainBackground);
}

.pageheader h2 {
  color: var(--pageHeaderTitle);
}

button.main {
  background-color: var(--buttonMain);
  color: var(--buttonMainTextColor);
}

button.main:hover {
  background-color: var(--buttonMainHover);
  color: var(--buttonMainTextColor);
}

button.action {
  padding: 0.3rem 0.6rem;
  border-radius: 32px;
}

/*    style overrides    */

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover{
  background-color: var(--accentColor) !important;
  color: var(--buttonMainTextColor) !important;
}

.rdtPicker {
  box-shadow: 2px 5px 12px 3px rgba(0,0,0,.1) !important;
  border-radius: 1em;
}

/*override only for dark, leave the default for light*/
body.dark .rdtPicker {
     background-color: var(--backgroundColor);
     border: 1px solid var(--backgroundColor);
   }

body.dark .rdtPicker thead tr:first-child th:hover,
body.dark .rdtCounter .rdtBtn:hover {
  background-color: rgba(255,255,255,0.2);
}

body.dark .rdtPicker th {
  border-bottom: none;
}

body.dark .rdtPicker tfoot {
  border-top: none;
}

body.dark .rdtPicker td.rdtDay:hover,
body.dark .rdtPicker td.rdtHour:hover,
body.dark .rdtPicker td.rdtMinute:hover,
body.dark .rdtPicker td.rdtSecond:hover,
body.dark .rdtPicker .rdtTimeToggle:hover {
  background: rgba(255,255,255,0.2);
}

.btn-link {
  color: var(--accentColor) !important;
}

.btn-link:hover {
  color: var(--lightAccentColor) !important;
  text-decoration: none !important;
}

.btn-link:focus {
  text-decoration: none !important;
  box-shadow: none !important;
}

.btn i {
  margin-right: 10px;
}

.btn-primary {
  background-color: var(--accentColor) !important;
  border-color: var(--accentColor) !important;
}
.btn-outline-primary{
  background-color: transparent;
  color: var(--lightAccentColor);
  border-color: var(--accentColor) !important;
}
.btn-primary:hover, .btn-outline-primary:hover,
button.main:hover {
  background-color: var(--lightAccentColor) !important;
  border-color: var(--lightAccentColor) !important;
}

.btn-outline-primary:not(:disabled):not(.disabled).active{
  background-color: var(--accentColor) !important;
  border-color: var(--accentColor) !important;
}

.form-control:focus {
  border-color: var(--lightAccentColor) !important;
  box-shadow: none;
}

.react-grid-item.react-grid-placeholder {
  background-color: var(--accentColor) !important;
}

/* Form JsonSchema */
.btn-add::after, .array-item-move-down::after,
.array-item-move-up::after, .array-item-remove::after {
    font-family: "Font Awesome 5 Free";
}

.formJsonSchema i.glyphicon { display: none; }

.formJsonSchema .btn-add::after {
    content: "Add";
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
.formJsonSchema .btn-add { margin-top: 1rem; }
.formJsonSchema .array-item-add { margin-left: 15px; }

.formJsonSchema .array-item-move-up::after { content: '\f062'; }
.formJsonSchema .array-item-move-down::after { content: '\f063'; }

.formJsonSchema .array-item-remove::after {
    content:'\f00d';
    font-weight: bold;
}

.formJsonSchema .array-item-list .field { margin: 1rem }
.formJsonSchema .form-additional { margin-left: 1rem; }

.formJsonSchema .col-xs-2{
    align-self: center;
    margin-left: 0.9rem;
}
/* desktop */
@media only screen and (min-width: 46.875em) {
  /*.app {*/
  /*  grid-template-columns: 188px 1fr;*/
  /*  grid-template-areas:*/
  /*    "header header"*/
  /*    "sidenav main";*/
  /*}*/
  .sidenav {
    position: relative;
    transform: translateX(0);
  }
}
.dx-state-invisible{
  display: none;
}
.default-cell-render{
  color: var(--tableText);
}

/* Filter Editor */
.filter-TB-editor-container-field button i{
  margin: 0;
}

/* Widget and Wrapper Editors */
.editor-tab {
  overflow-y: auto;
  overflow-x: hidden;
  height: 42rem;
}
.editor-footer {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

/* HTML EDITOR */
.editor-toolbar {
  font-size: 0.8rem;
}

.editor-toolbar button {
  height: 0;
}

.CodeMirror {
  height: 250px;
}

.CodeMirror-scroll {
  min-height: 200px !important;
}

.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}

.dx-htmleditor-content table {
  width: 50%;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.dx-field-item-content {
  text-align: right;
}

.dx-checkbox-icon {
  display: inline-block;
  position: relative;
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.dx-checkbox-container {
  height: 100%;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: end;
}

.dx-checkbox-has-text .dx-checkbox-icon,
.dx-checkbox-has-text .dx-checkbox-text {
  vertical-align: middle;
}

.dx-checkbox-text {
  display: inline-block;
  vertical-align: middle;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: normal;
  height: 100%;
  width: auto;
}

/* Links color changed for dark theme */
.dark a {
  color: rgb(6, 107, 239);
}
.dark a:hover {
  color: rgb(61, 133, 228);
}