.data-grid-component-content {
  height: calc(100% - 40px);
}
.data-grid-component-content .dx-container-component {
  height: max-content;
  overflow: hidden;
}

.data-grid-component-content td.column-date-box {
  color: var(--primaryText)!important;
}

.dx-datagrid-rowsview .dx-row{
  border: none;
  cursor: pointer;
}

/* Hover */
.dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused){
  opacity: 0.80;
  color: var(--primaryText);
}

/* Selection */
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td{
  background-color: var(--secondaryText)!important;
  border: none;
}

/* Background theme dark DateGrid */
body.dark .dx-datagrid{
  background-color: var(--mainColor)!important;
}

/* Font family */
.dx-datagrid {
  font-family: 'IBM Plex Sans', sans-serif;
}

/* Header */
.dx-datagrid-headers{
  font-weight: bold;
  color: var(--primaryText);
  border-bottom: none;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  background-color: var(--tableBackgroud);
}

/* Row size */
.dx-datagrid-table .dx-row > td {
  padding: 4px;
  height: 29px;
  background-color: var(--tableStripe);
  color: var(--primaryText);
}
.dx-datagrid-table .dx-row.dx-freespace-row {
  display: none;
}

/* Freeze */
body.dark .dx-col-fixed{
  background-color: var(--mainColor)!important;
  padding: 1rem;
}

/* Row alternation */
.dx-datagrid-table .dx-row-alt > td {
  background-color: var(--tableBackgroud)!important;
  border: none;
}
.dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td{
  background-color: transparent!important;
  border-top: none!important;
  border-bottom: none!important;
}
.dx-datagrid-rowsview .dx-row{
  border-top: none!important;
  border-bottom: none!important;
}

/* Hide no data text when it's supposed to be hidden */
.dx-datagrid-nodata.dx-hidden {
  display: none !important;
}

/* Editing buttons */
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .column-date-box .dx-icon,
.btn-change-status,
.active-cell-icon {
  color: var(--secondaryText) !important;
  font-size: 16px;
  width: 40px;
  text-decoration: none;
  padding: 0;
  line-height: 18px;
}
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .column-date-box .dx-icon:hover,
.btn-change-status:hover {
  color: var(--accentColor) !important;
}
.btn-change-status i {
  margin: 0 3px;
  height: 18px;
}

.container-button-download-table {
  position: absolute;
  bottom: 30px;
  right: 20px;
  opacity: 0.4;
  transition: all .2s ease-in-out;
}

.container-button-download-table .dropdown {
  background-color: var(--tableBackgroud);
  border-radius: 5rem;
}

.container-button-download-table:hover {
  opacity: 1;
}

.dx-datagrid .dx-gridbase-container {
  height: auto;
}
/* Footer Table */
.dx-datagrid-summary-item{
  color: var(--primaryText)!important;
}

/* Width fixes with sorting mode */
.dx-datagrid .column-date-box .dx-column-indicators {
  display: none;
  padding: 5px 0px !important
}
.dx-datagrid .column-date-box .dx-column-indicators:has(.dx-sort-up),
.dx-datagrid .column-date-box .dx-column-indicators:has(.dx-sort-down) {
  display: inline-block;
}
.dx-datagrid .dx-column-indicators.dx-visibility-hidden {
  display: none !important;
}
.dx-datagrid .dx-header-row .column-date-box .dx-sort-indicator {
  padding: 5px 0px 5px 5px;
  margin: 0px;
  max-width: 100% !important;
}